.App {
  text-align: center;
}

.header {
  color: white;
}

.authenticationPage {
  /* background: #efefef; */
  height: 106vh;
  overflow: hidden;
}

.buttonColor {
  color: white;
  background: #cc0000;
  width: 90%;
}


.cdocs-appHeading.C-Heading {
  font-weight: 400;
  letter-spacing: 0.5px;
}

.C-ExpandableSection__TopRow {
  padding-left: 0px;
  padding-bottom: 10px;
}

.C-Chip.\--dense {
  margin-top: -5px;
}
.reopenModal .C-Modal__container {
  margin-bottom: 350px;
}

div.ag-root .ag-cell-focus {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}


.C-Layout__backgroundCover {
  background: var(--canvas-background-color-white);
}

.hc-bg-teal {
  background: var(--canvas-background-color--violet);
  color: var(--canvas-theme-color--text);
}

.hc-bg-pink {
  background: var(--canvas-background-color--pink);
  color: var(--canvas-theme-color--text);
}

.hc-bg-indigo {
  background: var(--canvas-background-color--indigo);
  color: var(--canvas-theme-color--text);
}

.hc-dropdown-elevation {
  box-shadow: var(--canvas-elevation-5)
}

.hc-clr-dark {
  color: #000;
}

.C-DropdownMenu {
  border: 1px solid  var(--canvas-border-color--contrast) !important;
}

.hc-va-sub {
  vertical-align: sub;
}
